import { useEffect } from 'react';
import { useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import Nav from '../components/Nav/Nav';
import { useAppDispatch, useAppSelector } from '../state/store';
import { getDevices, getOrganisations, getShoots } from '../state/features/app';
import CreateShootDialogue from '../components/CreateShootDialogue/CreateShootDialogue';
import ConfirmDialogue from '../components/ConfirmDialogue/ConfirmDialogue';
import { deleteShoot } from '../api';
import Device from '../models/Device';
import { useOrganisation, useRTMPUrlBuilder, useShoot } from '../hooks';
import CopyKbd from '../components/CopyKbd/CopyKbd';
import { SHOOT_URL_PREFIX, CAPTURE_CAPTAIN_PREFIX } from '../globals';

export default function Shoot() {
    const dispatch = useAppDispatch();
    const [isEditDialogueOpen, setIsEditDialogueOpen] = useState(false);

    const [editShootId, setEditShootId] = useState('');
    const [deleteShootId, setDeleteShootId] = useState('');

    const { devices } = useAppSelector((s) => ({
        devices: s.app.devices,
    }));

    useEffect(() => {
        dispatch(getShoots());
        dispatch(getDevices());
        dispatch(getOrganisations());
    }, [dispatch]);

    let { id } = useParams() as { id: string };
    const shoot = useShoot(id);
    const organisation = useOrganisation(shoot?.organisationId || '');
    const rtmlUrl = useRTMPUrlBuilder(shoot?.organisationId || '');

    async function handleDeleteShoot(deleteShootId: string) {
        try {
            await deleteShoot(deleteShootId);
        } catch (e) {
            console.error(e);
        }

        setDeleteShootId('');
        dispatch(getShoots());
    }

    function getDevice(id: string): Device | undefined {
        return devices.find((d) => d.id === id);
    }

    const shootUrl = SHOOT_URL_PREFIX + shoot?.id;
    const captureCaptainUrl = CAPTURE_CAPTAIN_PREFIX + shoot?.id;

    return (
        <>
            <Nav />
            <div className="page page_shoot">
                <main className="container">
                    {shoot && (
                        <div>
                            <h1>
                                {shoot.name}
                                <a
                                    href={shootUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <MdOutlineOpenInNew />
                                </a>
                            </h1>
                            <CopyKbd>{shootUrl}</CopyKbd>
                            <br />
                            <br />

                            {organisation && (
                                <>
                                    <dl>
                                        <dt>Organisation</dt>
                                        <dd>{organisation.name}</dd>
                                    </dl>
                                </>
                            )}

                            <dl>
                                <dt>Start Date</dt>
                                <dd>{shoot.startDate || '-'}</dd>
                            </dl>

                            <dl>
                                <dt>End Date</dt>
                                <dd>{shoot.endDate || '-'} </dd>
                            </dl>

                            <dl>
                                <dt>Password</dt>
                                <dd>
                                    {shoot.passwordEnabled
                                        ? 'Enabled'
                                        : 'Disabled'}
                                </dd>
                            </dl>

                            <dl>
                                <dt>
                                    Capture Captain
                                    <a
                                        href={captureCaptainUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <MdOutlineOpenInNew />
                                    </a>
                                </dt>
                                <dd>
                                    {shoot.pilotEnabled ? (
                                        <CopyKbd>{captureCaptainUrl}</CopyKbd>
                                    ) : (
                                        'Disabled'
                                    )}
                                </dd>
                            </dl>

                            <dl>
                                <dt>Logo</dt>
                                <dd>
                                    <img
                                        alt="Shoot Logo"
                                        src={shoot.logoUrl}
                                        style={{
                                            width: 300,
                                            backgroundColor: '#aaa',
                                        }}
                                    />
                                </dd>
                            </dl>

                            {shoot.rooms.map((room) => (
                                <div key={room.id}>
                                    <hr />

                                    <dl>
                                        <dt>Text Chat</dt>
                                        <dd>
                                            {room.textChatEnabled
                                                ? 'Enabled'
                                                : 'Disabled'}
                                        </dd>
                                    </dl>

                                    <dl>
                                        <dt>Video Chat</dt>
                                        <dd>
                                            {room.videoChatEnabled
                                                ? 'Enabled'
                                                : 'Disabled'}
                                        </dd>
                                    </dl>

                                    <dl>
                                        <dt>Conference (ANT)</dt>
                                        <dd>
                                            {room.conferenceEnabled
                                                ? 'Enabled'
                                                : 'Disabled'}
                                        </dd>
                                    </dl>

                                    {room.conferenceEnabled && (
                                        <>
                                            <dl>
                                                <dt>Conference Room</dt>
                                                <dd>
                                                    <CopyKbd>
                                                        {room.conferenceRoomId}
                                                    </CopyKbd>
                                                </dd>
                                            </dl>
                                        </>
                                    )}

                                    <dl>
                                        <dt>Devices</dt>
                                    </dl>

                                    <table>
                                        <tbody>
                                            {Object.values(
                                                room.roomDevices
                                            ).map((rd) => {
                                                const d = getDevice(
                                                    rd.deviceId
                                                );
                                                return (
                                                    <tr key={rd.deviceId}>
                                                        <td>{d?.name}</td>
                                                        <td>{rd.label}</td>
                                                        <td>
                                                            <CopyKbd>
                                                                {rtmlUrl(
                                                                    d?.streamId
                                                                )}
                                                            </CopyKbd>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>
                    )}

                    <a
                        href="#"
                        role="button"
                        className="contrast outline"
                        onClick={() => {
                            setIsEditDialogueOpen(true);
                            setEditShootId(id);
                        }}
                    >
                        <BiEdit /> Edit Shoot
                    </a>
                </main>

                <CreateShootDialogue
                    isOpen={isEditDialogueOpen}
                    onClose={() => {
                        setIsEditDialogueOpen(false);
                    }}
                    onSubmit={() => {
                        setIsEditDialogueOpen(false);
                        dispatch(getShoots());
                    }}
                    editShootId={editShootId}
                />
                <ConfirmDialogue
                    open={deleteShootId !== ''}
                    title="Please Confirm"
                    heading="Are you sure?"
                    onCancel={() => {
                        setDeleteShootId('');
                    }}
                    onConfirm={() => {
                        handleDeleteShoot(deleteShootId);
                    }}
                >
                    Are you sure you want to delete the shoot?
                </ConfirmDialogue>
            </div>
        </>
    );
}
