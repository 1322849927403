import { useEscapeHandler } from '../../hooks';

export interface ConfirmDialogueProps {
    open: boolean;
    title: string;
    heading: string;
    children?: React.ReactNode;
    onCancel: () => void;
    onConfirm: () => void;
}

export default function ConfirmDialogue({
    open,
    title,
    heading,
    children,
    onCancel,
    onConfirm,
}: ConfirmDialogueProps) {
    useEscapeHandler(onCancel);

    return (
        <dialog open={open}>
            <article>
                <header>
                    <a
                        href="#close"
                        aria-label="Close"
                        className="close"
                        onClick={onCancel}
                    >
                        {' '}
                    </a>
                    {title}
                </header>
                <h3>{heading}</h3>
                {children}
                <footer>
                    <a
                        href="#cancel"
                        role="button"
                        className="secondary"
                        onClick={onCancel}
                    >
                        Cancel
                    </a>
                    <a href="#confirm" role="button" onClick={onConfirm}>
                        Confirm
                    </a>
                </footer>
            </article>
        </dialog>
    );
}
