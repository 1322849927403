import { useEffect, useState } from 'react';
import Nav from '../components/Nav/Nav';
import { useAppDispatch, useAppSelector } from '../state/store';
import { getOrganisations, getUsers } from '../state/features/app';
import CreateUserDialogue from '../components/CreateUserDialogue/CreateUserDialogue';
// import { BiEdit } from 'react-icons/bi';

export default function Users() {
    const [isShowingDialogue, setIsShowingDialogue] = useState(false);
    const dispatch = useAppDispatch();

    const { users, organisations } = useAppSelector((s) => ({
        users: s.app.users,
        organisations: s.app.organisations,
    }));

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getOrganisations());
    }, [dispatch]);

    function onNewUserSubmitted() {
        dispatch(getUsers());
        setIsShowingDialogue(false);
    }

    return (
        <>
            <Nav />
            <div className="page page_users">
                <main className="container">
                    <table>
                        <thead>
                            <tr>
                                <td>E-Mail</td>
                                <td>Name</td>
                                <td>Organisations</td>
                                <td>Role</td>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((u) => (
                                <tr key={u.id}>
                                    <td>{u.email}</td>
                                    <td>{u.name}</td>
                                    <td>
                                        {u.organisationIds &&
                                            u.organisationIds
                                                .map(
                                                    (id) =>
                                                        organisations.find(
                                                            (o) => o.id === id
                                                        )?.name
                                                )
                                                .join(', ')}
                                    </td>
                                    <td>{u.role.toLowerCase()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <a
                        href="#"
                        role="button"
                        className="contrast outline"
                        onClick={() => {
                            setIsShowingDialogue(true);
                        }}
                    >
                        + Create User
                    </a>
                </main>

                <CreateUserDialogue
                    isOpen={isShowingDialogue}
                    onSubmit={onNewUserSubmitted}
                    onClose={() => setIsShowingDialogue(false)}
                />
            </div>
        </>
    );
}
