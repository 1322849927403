import { FormEvent, useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    createShoot,
    setShootPassword,
    syncDailyRooms,
    updateShoot,
} from '../../api';
import { useEscapeHandler } from '../../hooks';
import Shoot, { Room } from '../../models/Shoot';
import { useAppSelector } from '../../state/store';
import ImageUploader from '../ImageUploader/ImageUploader';

import './CreateShootDialogue.scss';

interface CreateDeviceDialogueProps {
    isOpen: boolean;
    onSubmit: () => void;
    onClose: () => void;
    editShootId?: string;
}

export default function CreateShootDialogue({
    isOpen,
    onSubmit,
    onClose,
    editShootId,
}: CreateDeviceDialogueProps) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [password, setPassword] = useState('');
    const [deviceLabels, setDeviceLabels] = useState<{ [key: string]: string }>(
        {}
    );

    useEscapeHandler(onClose);

    function makeEmptyShoot(): Shoot {
        return {
            id: '',
            name: '',
            organisationId: '',
            startDate: '',
            endDate: '',
            logoUrl: '',
            passwordEnabled: false,
            pilotEnabled: false,
            pilotId: '',
            rooms: [
                {
                    id: uuidv4(),
                    name: '',
                    textChatEnabled: false,
                    videoChatEnabled: false,
                    conferenceEnabled: false,
                    roomDevices: {},
                },
            ],
        };
    }

    const [shoot, setShoot] = useState<Shoot>(makeEmptyShoot());

    const { devices, organisations, shoots } = useAppSelector((s) => ({
        devices: s.app.devices,
        organisations: s.app.organisations,
        shoots: s.app.shoots,
    }));

    const setShootField = useCallback(
        (field: keyof Shoot, value: any) => {
            setShoot({
                ...shoot,
                [field]: value,
            });
        },
        [setShoot, shoot]
    );

    // Set org to first in list to help with dev.
    // If only one org select by default.
    useEffect(() => {
        if (shoot.organisationId === '' && organisations.length > 0) {
            // setOrgId(organisations[0].id);
            setShootField('organisationId', organisations[0].id);
        }
    }, [organisations, setShootField, shoot]);

    // Load shoot values if editing a shoot, otherwise start with a clean slate
    // Runs every time dialogue is opened or closed
    useEffect(() => {
        if (editShootId) {
            const shoot = shoots.find((d) => d.id === editShootId);
            if (shoot) {
                setShoot(JSON.parse(JSON.stringify(shoot)));

                const deviceLabels: { [key: string]: string } = {};
                shoot.rooms.forEach((r) => {
                    Object.values(r.roomDevices).forEach((rd) => {
                        deviceLabels[rd.deviceId] = rd.label;
                    });
                });

                setDeviceLabels(deviceLabels);
            }
        } else {
            setShoot(makeEmptyShoot());
            setPassword('');
            setDeviceLabels({});
        }
    }, [editShootId, isOpen, shoots]);

    function setShootFieldCallback(field: keyof Shoot) {
        return (e: any) => {
            setShootField(field, e.target.value);
        };
    }

    function setRoomField(index: number, field: keyof Room, value: any) {
        const rooms = shoot.rooms;
        const newRoom = {
            ...rooms[index],
            [field]: value,
        };
        rooms[index] = newRoom;
        setShootField('rooms', rooms);
    }

    function toggleDevice(
        roomIndex: number,
        deviceId: string,
        enabled: boolean
    ) {
        const rooms = shoot.rooms;
        const roomDevices = rooms[roomIndex].roomDevices;

        if (enabled) {
            roomDevices[deviceId] = {
                deviceId,
                label: '', // label: deviceLabels[deviceId],
            };
        } else {
            if (deviceId in roomDevices) {
                delete roomDevices[deviceId];
            }
        }

        rooms[roomIndex].roomDevices = roomDevices;

        setShootField('rooms', rooms);
    }

    async function onSubmitHandler(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setIsSubmitting(true);

        shoot.rooms = shoot.rooms.map((r) => {
            Object.keys(r.roomDevices).forEach((deviceId) => {
                r.roomDevices[deviceId].label = deviceLabels[deviceId];
                if (r.conferenceEnabled && !r.conferenceRoomId) {
                    r.conferenceRoomId = uuidv4();
                }
                if (!r.conferenceEnabled) {
                    r.conferenceRoomId = '';
                }
            });
            return r;
        });

        try {
            if (editShootId) {
                await updateShoot(shoot);
            } else {
                const newId = await createShoot(shoot);
                shoot.id = newId;
            }

            if (shoot.passwordEnabled && password !== '') {
                await setShootPassword(shoot.id, password);
            }

            await syncDailyRooms();
        } catch (error) {
            console.error(error);
        }

        setIsSubmitting(false);
        onSubmit();
    }

    useEffect(() => {
        if (shoot.pilotEnabled && !shoot.pilotId) {
            setShootField('pilotId', uuidv4());
        }
    }, [shoot.pilotEnabled, shoot.pilotId, setShootField]);

    // function addRoom() {
    //     setShoot({
    //         ...shoot,
    //         rooms: [
    //             ...shoot.rooms,
    //             {
    //                 id: uuidv4(),
    //                 name: '',
    //                 textChatEnabled: false,
    //                 videoChatEnabled: false,
    //                 conferenceEnabled: false,
    //                 roomDevices: {},
    //             },
    //         ],
    //     });
    // }

    return (
        <dialog open={isOpen} className="CreateShootDialogue">
            <article>
                <form
                    onSubmit={onSubmitHandler}
                    style={{
                        minWidth: 500,
                    }}
                >
                    <label htmlFor="shootName">
                        Shoot Name
                        <br />
                        <br />
                    </label>
                    <input
                        id="shootName"
                        placeholder="Shoot Name"
                        required
                        value={shoot.name}
                        onChange={setShootFieldCallback('name')}
                    />

                    <label htmlFor="organisation">Organisation</label>
                    <select
                        id="organisation"
                        required
                        value={shoot.organisationId}
                        onChange={setShootFieldCallback('organisationId')}
                    >
                        <option value="">Select Org</option>
                        {organisations.map((d) => (
                            <option value={d.id} key={d.id}>
                                {d.name}
                            </option>
                        ))}
                    </select>

                    <label htmlFor="startDate">
                        Start Date
                        <input
                            type="date"
                            id="startDate"
                            name="date"
                            value={shoot.startDate}
                            onChange={setShootFieldCallback('startDate')}
                        />
                    </label>

                    <label htmlFor="endDate">
                        End Date
                        <input
                            type="date"
                            id="endDate"
                            name="date"
                            value={shoot.endDate}
                            onChange={setShootFieldCallback('endDate')}
                        />
                    </label>
                    <hr />
                    <ImageUploader
                        onSuccess={(url) => setShootField('logoUrl', url)}
                        onError={() => setShootField('logoUrl', '')}
                        currentImage={shoot.logoUrl}
                        title="Logo"
                        desc="We don't transform this on the backend so plese keep it to a reasonable filesize. Recommended under 800px max and under 250kb"
                    />
                    <hr />

                    <div className="grid">
                        <label htmlFor="pilotEnabled">
                            <input
                                type="checkbox"
                                id="pilotEnabled"
                                name="pilotEnabled"
                                checked={shoot.pilotEnabled}
                                onChange={(e) => {
                                    setShootField(
                                        'pilotEnabled',
                                        e.target.checked
                                    );
                                }}
                            />
                            Capture Captain Enabled
                        </label>

                        <label>
                            <input disabled={true} value={shoot.pilotId} />
                        </label>
                    </div>

                    <div className="grid">
                        <label htmlFor="hasPassword">
                            <input
                                type="checkbox"
                                id="hasPassword"
                                name="hasPassword"
                                checked={shoot.passwordEnabled}
                                onChange={(e) => {
                                    setShootField(
                                        'passwordEnabled',
                                        e.target.value
                                    );
                                }}
                            />
                            Password Enabled
                        </label>

                        <label htmlFor="password">
                            <input
                                id="password"
                                placeholder="Password"
                                disabled={!shoot.passwordEnabled}
                                value={shoot.passwordEnabled ? password : ''}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </label>
                    </div>

                    {shoot.rooms.map((room, i) => (
                        <div key={room.id}>
                            <summary>{room.name ? room.name : 'Room '}</summary>
                            <label htmlFor={`room_${room.id}_name`}>
                                Room Name
                                <input
                                    id={`room_${room.id}_name`}
                                    placeholder="Room Name"
                                    value={room.name}
                                    onChange={(e) => {
                                        setRoomField(i, 'name', e.target.value);
                                    }}
                                />
                            </label>

                            <label>Devices</label>
                            {devices
                                .filter(
                                    (d) =>
                                        d.organisationId ===
                                        shoot.organisationId
                                )
                                .map((d) => (
                                    <div className="grid" key={d.id}>
                                        <label
                                            htmlFor={`room_${room.id}_deviceEnabled_${d.id}`}
                                        >
                                            <input
                                                type="checkbox"
                                                id={`room_${room.id}_deviceEnabled_${d.id}`}
                                                name={d.id}
                                                checked={
                                                    d.id in room.roomDevices
                                                }
                                                onChange={(e) => {
                                                    toggleDevice(
                                                        i,
                                                        d.id,
                                                        e.target.checked
                                                    );
                                                }}
                                            />
                                            {d.name}
                                        </label>

                                        <label
                                            htmlFor={`room_${room.id}_deviceLabel_${d.id}`}
                                        >
                                            <input
                                                id={`room_${room.id}_deviceLabel_${d.id}`}
                                                placeholder="Label"
                                                value={deviceLabels[d.id] || ''}
                                                required={
                                                    d.id in room.roomDevices
                                                }
                                                onChange={(e) => {
                                                    setDeviceLabels({
                                                        ...deviceLabels,
                                                        [d.id]: e.target.value,
                                                    });
                                                }}
                                            />
                                        </label>
                                    </div>
                                ))}

                            <label htmlFor={`room_${room.id}_textchat_enabled`}>
                                <input
                                    id={`room_${room.id}_textchat_enabled`}
                                    type="checkbox"
                                    checked={room.textChatEnabled}
                                    onChange={(e) => {
                                        setRoomField(
                                            i,
                                            'textChatEnabled',
                                            e.target.checked
                                        );
                                    }}
                                />
                                Text Chat Enabled
                            </label>
                            <label
                                htmlFor={`room_${room.id}_videochat_enabled`}
                            >
                                <input
                                    id={`room_${room.id}_videochat_enabled`}
                                    type="checkbox"
                                    checked={room.videoChatEnabled}
                                    onChange={(e) => {
                                        setRoomField(
                                            i,
                                            'videoChatEnabled',
                                            e.target.checked
                                        );
                                    }}
                                />
                                Video Chat Enabled
                            </label>
                            <label
                                htmlFor={`room_${room.id}_conference_enabled`}
                            >
                                <input
                                    id={`room_${room.id}_conference_enabled`}
                                    type="checkbox"
                                    checked={room.conferenceEnabled}
                                    onChange={(e) => {
                                        setRoomField(
                                            i,
                                            'conferenceEnabled',
                                            e.target.checked
                                        );
                                    }}
                                />
                                Conference (Ant)
                            </label>
                        </div>
                    ))}
                    <hr />

                    {/* <button onClick={addRoom}>+ Add Room</button> */}

                    <button
                        type="submit"
                        aria-busy={isSubmitting ? 'true' : 'false'}
                    >
                        {editShootId ? 'Update Shoot' : 'Create Shoot'}
                    </button>
                </form>
                <button className="warning" onClick={onClose}>
                    Cancel
                </button>
            </article>
        </dialog>
    );
}
