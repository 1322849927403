import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';

import './App.scss';
import Users from './pages/Users';
import { useEffect, useState } from 'react';

import { getAuth } from 'firebase/auth';
import { useAppDispatch, useAppSelector } from './state/store';
import { setUser } from './state/features/auth';
import Organisations from './pages/Organisations';
import FourOhFour from './pages/404';
import Devices from './pages/Devices';
import Shoots from './pages/Shoots';
import { getUser } from './api';
import Shoot from './pages/Shoot';

const auth = getAuth();
function App() {
    const dispatch = useAppDispatch();

    const [timeElasped, setTimeElasped] = useState(false);

    const { user } = useAppSelector((s) => ({
        user: s.auth.user,
    }));

    useEffect(() => {
        setTimeout(() => {
            setTimeElasped(true);
        }, 1000);

        auth.onAuthStateChanged(async function (user) {
            if (user) {
                const u = await getUser(user.uid);

                dispatch(
                    setUser({
                        id: user.uid,
                        email: user.email || '',
                        name: user.displayName || '',
                        organisationIds: u.organisationIds,
                        role: u.role,
                    })
                );
            } else {
                dispatch(setUser(undefined));
                // history.replace('/');
            }
        });
    }, [dispatch]);

    return (
        <Router>
            {timeElasped && !user && <Route path="/" component={Login} />}
            {user && (
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/users" component={Users} />
                    <Route
                        exact
                        path="/organisations"
                        component={Organisations}
                    />
                    <Route exact path="/devices" component={Devices} />
                    <Route exact path="/shoots" component={Shoots} />
                    <Route exact path="/shoots/:id" component={Shoot} />

                    <Route component={FourOhFour} />
                </Switch>
            )}
        </Router>
    );
}

export default App;
