import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyDl9xAZelLE4gJJzKUyrmXfqAjgiKwTt3s',
    authDomain: 'shoot-hub.firebaseapp.com',
    projectId: 'shoot-hub',
    storageBucket: 'shoot-hub.appspot.com',
    messagingSenderId: '38861606039',
    appId: '1:38861606039:web:0f2dfd0f61a1a891162dd2',
};

initializeApp(firebaseConfig);

export {};
