import { useCallback, useEffect } from 'react';
import Organisation from '../models/Organisation';
import Shoot from '../models/Shoot';
import User from '../models/User';
import { useAppSelector } from '../state/store';

export function useEscapeHandler(cb: Function) {
    const escFunction = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                cb();
            }
        },
        [cb]
    );

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);
}

export function useOrganisation(orgId: string): Organisation | undefined {
    const { organisations } = useAppSelector((s) => ({
        organisations: s.app.organisations,
    }));

    return organisations.find((o) => o.id === orgId);
}

export function useUser(): User | undefined {
    return useAppSelector((s) => s.auth.user);
}

export function useShoot(id: string): Shoot | undefined {
    const { shoots } = useAppSelector((s) => ({
        shoots: s.app.shoots,
    }));

    return shoots.find((o) => o.id === id);
}

export function useRTMPUrlBuilder(
    orgId: string
): (id: string | undefined) => string {
    const { organisations } = useAppSelector((s) => ({
        organisations: s.app.organisations,
    }));

    const org = organisations.find((o) => o.id === orgId);

    return (id: string | undefined) => {
        if (!org) return '';

        return org.rtmpUrl + id;
    };
}
