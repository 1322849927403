import { FormEvent, useState } from 'react';
import { createDevice } from '../../api';
import { useAppSelector } from '../../state/store';

interface CreateDeviceDialogueProps {
    isOpen: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

export default function CreateDeviceDialogue({
    isOpen,
    onSubmit,
    onClose,
}: CreateDeviceDialogueProps) {
    const [deviceName, setDeviceName] = useState('');
    const [orgId, setOrgId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { organisations } = useAppSelector((s) => ({
        organisations: s.app.organisations,
    }));

    async function onSubmitHandler(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setIsSubmitting(true);
        if (deviceName.length < 1) {
            setErrorMessage('Name too short.');
            setIsSubmitting(false);
            return;
        }
        if (orgId.length < 1) {
            setErrorMessage('Please select an organisation.');
            setIsSubmitting(false);
            return;
        }

        try {
            await createDevice(deviceName, orgId);
        } catch (error) {
            console.error(error);
        }
        onSubmit();
        setIsSubmitting(false);
        setDeviceName('');
        setOrgId('');
        setErrorMessage('');
    }

    return (
        <dialog open={isOpen}>
            <article>
                <form onSubmit={onSubmitHandler}>
                    <label htmlFor="deviceName">
                        Device Name
                        <br />
                        <br />
                    </label>
                    <input
                        id="deviceName"
                        placeholder="Device Name"
                        required
                        value={deviceName}
                        onChange={(e) => setDeviceName(e.target.value)}
                    />
                    <label htmlFor="organisation">Organisation</label>
                    <select
                        id="organisation"
                        required
                        value={orgId}
                        onChange={(e) => setOrgId(e.target.value)}
                    >
                        <option value="">Select Org</option>
                        {organisations.map((d) => (
                            <option value={d.id} key={d.id}>
                                {d.name}
                            </option>
                        ))}
                    </select>

                    <div className="error">{errorMessage}</div>

                    <button
                        type="submit"
                        aria-busy={isSubmitting ? 'true' : 'false'}
                    >
                        Submit
                    </button>
                </form>
                <button className="warning" onClick={onClose}>
                    Cancel
                </button>
            </article>
        </dialog>
    );
}
