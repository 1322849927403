import { Link, useLocation } from 'react-router-dom';
import { VscLoading } from 'react-icons/vsc';

import { useUser } from '../../hooks';

import logo from '../../assets/img/ShootHub.png';
import { useAppDispatch, useAppSelector } from '../../state/store';
import { getAuth } from 'firebase/auth';
import { setUser } from '../../state/features/auth';

export default function Nav() {
    let location = useLocation();
    let u = useUser();

    const dispatch = useAppDispatch();

    const routes = [
        {
            name: 'Shoots',
            path: '/shoots',
        },

        {
            name: 'Devices',
            path: '/devices',
        },
        {
            name: 'Users',
            path: '/users',
            superAdminOnly: true,
        },
        {
            name: 'Organisations',
            path: '/organisations',
            superAdminOnly: true,
        },
    ];

    const isLoading = useAppSelector(
        (s) =>
            s.app.isFetchingDevices ||
            s.app.isFetchingOrganisations ||
            s.app.isFetchingShoots ||
            s.app.isFetchingUsers
    );

    return (
        <nav className="container-fluid">
            <ul>
                <li>
                    <Link to="/">
                        <img src={logo} alt="Shoot Hub" />
                    </Link>
                </li>
                <li>
                    <VscLoading
                        size={24}
                        className={isLoading ? 'loading' : ''}
                    />
                </li>
            </ul>
            <ul>
                {routes
                    .filter((d) => {
                        if (!d.superAdminOnly) return true;

                        if (d.superAdminOnly && u && u.role === 'SUPERADMIN') {
                            return true;
                        }

                        return false;
                    })
                    .map((d) => (
                        <li key={d.path}>
                            <Link
                                to={d.path}
                                className={
                                    location.pathname.startsWith(d.path)
                                        ? 'active'
                                        : ''
                                }
                            >
                                {d.name}
                            </Link>
                        </li>
                    ))}
                <li>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(setUser(undefined));
                            getAuth().signOut();
                        }}
                    >
                        Logout
                    </a>
                </li>
            </ul>
        </nav>
    );
}
