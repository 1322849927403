import { useEffect, useState } from 'react';
import { MdOutlineCopyAll } from 'react-icons/md';

interface CopyTextProps {
    children: React.ReactChild | undefined;
    copyString?: string;
    onClick?: () => void;
    showIcon?: boolean;
}

const originalTooltipText = 'Copy to clipboard';
const copiedTooltipText = 'Copied!';

export default function CopyKbd({
    children,
    onClick,
    showIcon,
    copyString,
}: CopyTextProps) {
    const [tooltipText, setTooltipText] = useState(originalTooltipText);

    useEffect(() => {
        let timeoutId: any;

        if (tooltipText === copiedTooltipText) {
            timeoutId = setTimeout(() => {
                setTooltipText(originalTooltipText);
            }, 2000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [tooltipText]);

    return (
        <div
            className="no-tooltip-underline"
            data-tooltip={tooltipText}
            style={{
                display: 'inline-block',
            }}
            onClick={async () => {
                let stringToCopy = '';

                if (typeof children === 'string') {
                    stringToCopy = children;
                }

                if (copyString) {
                    stringToCopy = copyString;
                }

                try {
                    await navigator.clipboard.writeText(stringToCopy);

                    if (onClick) {
                        onClick();
                    }

                    setTooltipText(copiedTooltipText);
                } catch (e) {
                    console.error(e);
                }
            }}
        >
            <kbd>{children}</kbd>
            {showIcon && <MdOutlineCopyAll size={24} />}
        </div>
    );
}
