import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from '../../models/User';

import * as api from './../../api';

export interface AuthState {
    isLoggingIn: boolean;
    loginError: string;
    user?: User;
}

const initialState: AuthState = {
    isLoggingIn: false,
    loginError: '',
};

export const login = createAsyncThunk(
    'auth/login',
    async (data: { email: string; password: string }) => {
        const { email, password } = data;
        await api.login(email, password);
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User | undefined>) => {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state, action) => {
                state.isLoggingIn = true;
                state.loginError = '';
            })
            .addCase(login.fulfilled, (state, action) => {
                // state.user = action.payload; // This is done by the authstatechange handler in App.tsx
                state.isLoggingIn = false;
                state.loginError = '';
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoggingIn = false;
                state.loginError =
                    action.error.message || 'Unknown Login Error';
            });
    },
});

export const { setUser } = authSlice.actions;
export default authSlice.reducer;
