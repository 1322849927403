import { useState } from 'react';
import { MdOutlineCopyAll } from 'react-icons/md';

interface CopyTextProps {
    text: string;
    onClick?: () => void;
}

let timeoutId: any;

const originalTooltipText = 'Copy id to clipboard';

export default function CopyText({ text, onClick }: CopyTextProps) {
    const [tooltipText, setTooltipText] = useState(originalTooltipText);

    return (
        <div
            className="no-tooltip-underline"
            data-tooltip={tooltipText}
            style={{
                display: 'inline-block',
            }}
            onClick={async () => {
                try {
                    await navigator.clipboard.writeText(text);
                    if (onClick) {
                        onClick();
                    }
                    clearTimeout(timeoutId);
                    setTooltipText('Copied!');
                    timeoutId = setTimeout(() => {
                        setTooltipText(originalTooltipText);
                    }, 2000);
                } catch (e) {
                    console.error(e);
                }
            }}
        >
            <MdOutlineCopyAll size={24} />
        </div>
    );
}
