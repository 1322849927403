import { FormEvent, useEffect, useState } from 'react';
import { createOrganisation, updateOrganisation } from '../../api';
import { useEscapeHandler, useOrganisation } from '../../hooks';

interface CreateOrganisationDialogueProps {
    open: boolean;
    onSubmit: () => void;
    onClose: () => void;
    openOrgId?: string;
}

export default function CreateOrganisationDialogue({
    open,
    onSubmit,
    onClose,
    openOrgId,
}: CreateOrganisationDialogueProps) {
    const [orgName, setOrgName] = useState('');
    const [websocketURL, setWebsocketURL] = useState('');
    const [rtmpURL, setRtmpURL] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const existingOrg = useOrganisation(openOrgId || '');

    useEscapeHandler(onClose);

    useEffect(() => {
        if (openOrgId) {
            if (existingOrg) {
                setOrgName(existingOrg.name);
                setWebsocketURL(existingOrg.websocketUrl);
                setRtmpURL(existingOrg.rtmpUrl);
            }
        } else {
            setOrgName('');
            setWebsocketURL('');
            setRtmpURL('');
        }
    }, [openOrgId, existingOrg]);

    async function onSubmitHandler(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setIsSubmitting(true);
        if (orgName.length < 1) {
            setErrorMessage('Name too short.');
            return;
        }
        if (websocketURL.length < 1) {
            setErrorMessage('Please supply the websocket URL.');
            return;
        }

        try {
            // Add trailing slash if needed
            let rtmpClean = rtmpURL;
            if (rtmpClean[rtmpClean.length - 1] !== '/')
                rtmpClean = rtmpClean + '/';

            if (openOrgId) {
                await updateOrganisation(openOrgId, {
                    name: orgName,
                    websocketUrl: websocketURL,
                    rtmpUrl: rtmpClean,
                });
            } else {
                await createOrganisation({
                    name: orgName,
                    websocketUrl: websocketURL,
                    rtmpUrl: rtmpClean,
                });
            }
        } catch (error) {
            console.error(error);
        }
        onSubmit();
        setIsSubmitting(false);
        setOrgName('');
        setWebsocketURL('');
        setErrorMessage('');
    }

    return (
        <dialog open={open}>
            <article>
                <form onSubmit={onSubmitHandler}>
                    <label htmlFor="orgName">
                        Organisation Name
                        <br />
                        <br />
                        <input
                            id="orgName"
                            placeholder="Organisation Name"
                            required
                            value={orgName}
                            onChange={(e) => setOrgName(e.target.value)}
                        />
                    </label>

                    <label htmlFor="websocketURL">
                        Websocket URL
                        <br />
                        <br />
                        <input
                            id="websocketURL"
                            placeholder="Websocket URL"
                            required
                            value={websocketURL}
                            onChange={(e) => setWebsocketURL(e.target.value)}
                        />
                    </label>

                    <label htmlFor="rtmpURL">
                        RTMP URL
                        <br />
                        <br />
                        <input
                            id="rtmpURL"
                            placeholder="Websocket URL"
                            required
                            value={rtmpURL}
                            onChange={(e) => setRtmpURL(e.target.value)}
                        />
                    </label>

                    {errorMessage}

                    <button
                        type="submit"
                        aria-busy={isSubmitting ? 'true' : 'false'}
                    >
                        Submit
                    </button>
                </form>
                <button className="warning" onClick={onClose}>
                    Cancel
                </button>
            </article>
        </dialog>
    );
}
