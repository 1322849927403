import { ChangeEvent, useEffect, useState } from 'react';
import { getUploadUrl } from '../../api';

interface ImageUploaderProps {
    currentImage?: string;
    onSuccess?: (filename: string) => void;
    onError?: (e: any) => void;
    title?: string;
    desc?: string;
}

// TODO: show error state
export default function ImageUploader({
    onSuccess = () => {},
    onError = () => {},
    currentImage,
    title,
    desc,
}: ImageUploaderProps) {
    const [previewUrl, setPreviewUrl] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if (currentImage) setPreviewUrl(currentImage);
    }, [currentImage]);

    async function handleFileSelect(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.files || e.target.files.length === 0) return;

        const file = e.target.files[0];
        const filename = file.name;

        const parts = filename.split('.');
        const extension = parts[parts.length - 1];

        setPreviewUrl(URL.createObjectURL(file));
        setIsUploading(true);

        let url;
        try {
            url = await getUploadUrl(extension);
        } catch (e) {
            console.error(e);
            onError(e);
            return;
        }

        try {
            await fetch(url, {
                method: 'PUT',
                body: file,
            });
        } catch (e) {
            console.error(e);
            onError(e);
            return;
        }

        const publicUrl = url.split('?')[0];

        onSuccess(publicUrl);
    }

    return (
        <div className="grid">
            <label htmlFor="file">
                {title}
                {desc && (
                    <>
                        <br />
                        <span
                            style={{
                                fontSize: '14px',
                            }}
                        >
                            {desc}
                        </span>
                    </>
                )}
                <input
                    type="file"
                    id="file"
                    name="file"
                    onChange={handleFileSelect}
                />
                <br />
                {isUploading ? 'Uploading' : ''}
                <br />
            </label>

            {previewUrl && (
                <img
                    src={previewUrl}
                    style={{
                        height: 200,
                    }}
                    alt="Logo"
                />
            )}
        </div>
    );
}
