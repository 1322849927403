import { useEffect, useState } from 'react';

import Nav from '../components/Nav/Nav';
import { useAppDispatch, useAppSelector } from '../state/store';
import { getDevices, getOrganisations } from '../state/features/app';
import CreateDeviceDialogue from '../components/CreateDeviceDialogue/CreateDeviceDialogue';
import CopyText from '../components/CopyText/CopyText';

export default function Devices() {
    const dispatch = useAppDispatch();

    const [isShowingDialogue, setIsShowingDialogue] = useState(false);

    const { devices, organisations } = useAppSelector((s) => ({
        devices: s.app.devices,
        organisations: s.app.organisations,
    }));

    useEffect(() => {
        dispatch(getDevices());
        dispatch(getOrganisations());
    }, [dispatch]);

    function onNewDeviceSubmitted() {
        dispatch(getDevices());
        setIsShowingDialogue(false);
    }

    return (
        <>
            <Nav />
            <div className="page page_users">
                <main className="container">
                    <table>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td>StreamId</td>
                                <td>Organisation</td>
                            </tr>
                        </thead>
                        <tbody>
                            {devices.map((d) => {
                                const org = organisations.find(
                                    (o) => o.id === d.organisationId
                                );
                                return (
                                    <tr key={d.id}>
                                        <td>{d.name}</td>
                                        <td>
                                            <kbd>
                                                {org?.rtmpUrl + d.streamId}
                                            </kbd>{' '}
                                            <CopyText
                                                text={org?.rtmpUrl + d.streamId}
                                            />
                                        </td>
                                        <td>{org?.name}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <a
                        href="#"
                        role="button"
                        className="contrast outline"
                        onClick={() => setIsShowingDialogue(true)}
                    >
                        + Add Device
                    </a>
                </main>

                <CreateDeviceDialogue
                    isOpen={isShowingDialogue}
                    onSubmit={onNewDeviceSubmitted}
                    onClose={() => setIsShowingDialogue(false)}
                />
            </div>
        </>
    );
}
