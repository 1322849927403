import Nav from '../components/Nav/Nav';

export default function FourOhFour() {
    return (
        <>
            <Nav />
            <div className="page page_home">
                <main className="container">
                    <h1>Page Not Found</h1>
                </main>
            </div>
        </>
    );
}
