import { MouseEvent, useEffect, useState } from 'react';
import Nav from '../components/Nav/Nav';
import { useAppDispatch, useAppSelector } from '../state/store';
import { getOrganisations } from '../state/features/app';
import CreateOrganisationDialogue from '../components/CreateOrganisationDialogue/CreateOrganisationDialogue';
import { BiEdit } from 'react-icons/bi';

export default function Organisations() {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [openOrgId, setOpenOrgId] = useState<string | undefined>();

    const dispatch = useAppDispatch();

    const { organisations } = useAppSelector((s) => ({
        organisations: s.app.organisations,
    }));

    useEffect(() => {
        dispatch(getOrganisations());
    }, [dispatch]);

    function onAddOrgClick(e: MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        setIsDialogOpen(true);
    }

    async function onNewSubmit() {
        dispatch(getOrganisations());
        setIsDialogOpen(false);
        setOpenOrgId('');
    }

    function onNewCancel() {
        setOpenOrgId('');
        setIsDialogOpen(false);
    }

    return (
        <>
            <Nav />
            <div className="page page_organisations">
                <main className="container">
                    <table>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td>URLs</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {organisations.map((d) => (
                                <tr key={d.id}>
                                    <td>{d.name}</td>
                                    <td>
                                        <kbd>{d.websocketUrl}</kbd>
                                        <br />
                                        <br />
                                        <kbd>{d.rtmpUrl}</kbd>
                                    </td>
                                    <td>
                                        <BiEdit
                                            onClick={() => {
                                                setOpenOrgId(d.id);
                                                setIsDialogOpen(true);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <a
                        href="#"
                        role="button"
                        className="contrast outline"
                        onClick={onAddOrgClick}
                    >
                        + Add Organisation
                    </a>
                </main>

                <CreateOrganisationDialogue
                    open={isDialogOpen}
                    onClose={onNewCancel}
                    onSubmit={onNewSubmit}
                    openOrgId={openOrgId}
                />
            </div>
        </>
    );
}
