import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Nav from '../components/Nav/Nav';

export default function Home() {
    const history = useHistory();

    useEffect(() => {
        history.push('/shoots');
    }, [history]);

    return (
        <>
            <Nav />
        </>
    );
}
