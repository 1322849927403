import { useEffect } from 'react';
import { useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import Nav from '../components/Nav/Nav';
import { useAppDispatch, useAppSelector } from '../state/store';
import { getDevices, getOrganisations, getShoots } from '../state/features/app';
import CreateShootDialogue from '../components/CreateShootDialogue/CreateShootDialogue';
import ConfirmDialogue from '../components/ConfirmDialogue/ConfirmDialogue';
import { deleteShoot } from '../api';
import { Link } from 'react-router-dom';

export default function Shoots() {
    const dispatch = useAppDispatch();
    const [isCreateDialogueOpen, setIsCreateDialogueOpen] = useState(false);
    const [editShootId, setEditShootId] = useState('');
    const [deleteShootId, setDeleteShootId] = useState('');

    useEffect(() => {
        dispatch(getShoots());
        dispatch(getDevices());
        dispatch(getOrganisations());
    }, [dispatch]);

    const { organisations, shoots } = useAppSelector((s) => ({
        organisations: s.app.organisations,
        shoots: s.app.shoots,
    }));

    async function handleDeleteShoot(deleteShootId: string) {
        try {
            await deleteShoot(deleteShootId);
        } catch (e) {
            console.error(e);
        }

        setDeleteShootId('');
        dispatch(getShoots());
    }

    return (
        <>
            <Nav />
            <div className="page page_shoots">
                <main className="container">
                    <table>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td>Organisation</td>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {shoots.map((shoot) => (
                                <tr key={shoot.id}>
                                    <td>
                                        <Link to={`/shoots/${shoot.id}`}>
                                            {shoot.name}
                                        </Link>
                                    </td>
                                    <td>
                                        {
                                            organisations.find(
                                                (d) =>
                                                    d.id ===
                                                    shoot.organisationId
                                            )?.name
                                        }
                                    </td>
                                    <td>{shoot.startDate}</td>
                                    <td>{shoot.endDate}</td>
                                    <td>
                                        <BiTrash
                                            onClick={() => {
                                                setDeleteShootId(shoot.id);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <a
                        href="#"
                        role="button"
                        className="contrast outline"
                        onClick={() => {
                            setEditShootId('');
                            setIsCreateDialogueOpen(true);
                        }}
                    >
                        + Add Shoot
                    </a>
                </main>

                <CreateShootDialogue
                    isOpen={isCreateDialogueOpen}
                    onClose={() => {
                        setIsCreateDialogueOpen(false);
                    }}
                    onSubmit={() => {
                        setIsCreateDialogueOpen(false);
                        dispatch(getShoots());
                    }}
                    editShootId={editShootId}
                />

                <ConfirmDialogue
                    open={deleteShootId !== ''}
                    title="Please Confirm"
                    heading="Are you sure?"
                    onCancel={() => {
                        setDeleteShootId('');
                    }}
                    onConfirm={() => {
                        handleDeleteShoot(deleteShootId);
                    }}
                >
                    Are you sure you want to delete the shoot?
                </ConfirmDialogue>
            </div>
        </>
    );
}
