import { FormEvent, useState } from 'react';
import { createUser } from '../../api';
import { useEscapeHandler } from '../../hooks';
import { UserRole } from '../../models/User';
// import { createUser } from '../../api';
import { useAppSelector } from '../../state/store';

interface CreateUserDialogueProps {
    isOpen: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

export default function CreateUserDialogue({
    isOpen,
    onSubmit,
    onClose,
}: CreateUserDialogueProps) {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [organisationIds, setOrganisationIds] = useState<string[]>([]);
    const [role, setRole] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { organisations } = useAppSelector((s) => ({
        organisations: s.app.organisations,
    }));

    function setOrgId(id: string, value: boolean) {
        const newOrgIds = [...organisationIds];
        const index = organisationIds.indexOf(id);

        if (value && index === -1) {
            newOrgIds.push(id);
        }

        if (!value && index !== -1) {
            newOrgIds.splice(index);
        }

        setOrganisationIds(newOrgIds);
    }

    async function onSubmitHandler(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setIsSubmitting(true);

        await createUser({
            id: '',
            name: userName,
            email: email,
            organisationIds,
            role: role as UserRole,
            password,
        });

        setIsSubmitting(false);

        onSubmit();
    }

    function onCancel() {
        setUserName('');
        setEmail('');
        setPassword('');
        setOrganisationIds([]);
        setRole('');

        onClose();
    }

    useEscapeHandler(onCancel);

    return (
        <dialog open={isOpen}>
            <article>
                <form onSubmit={onSubmitHandler}>
                    <label htmlFor="UserName">
                        User Name
                        <br />
                        <br />
                    </label>
                    <input
                        id="UserName"
                        placeholder="User Name"
                        required
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                    />

                    <label htmlFor="email">
                        E-Mail
                        <br />
                        <br />
                    </label>
                    <input
                        id="email"
                        placeholder="E-Mail"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <label htmlFor="password">
                        Password
                        <br />
                        <br />
                    </label>
                    <input
                        id="password"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                    />

                    <label htmlFor="role">
                        Role
                        <br />
                        <br />
                    </label>
                    <select
                        id="role"
                        required
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <option value="">Select Role</option>
                        <option value="SUPERADMIN">
                            Superadmin (aka. the bois)
                        </option>
                        <option value="ADMIN">Regular Admin</option>
                        <option value="C1PILOT">Capture One Pilot</option>{' '}
                    </select>

                    <label>
                        Organisations. (only needed for non-superadmins)
                    </label>
                    {organisations.map((org) => (
                        <label key={org.id} htmlFor={`org_${org.id}`}>
                            <input
                                type="checkbox"
                                name={`org_${org.id}`}
                                id={`org_${org.id}`}
                                checked={organisationIds.indexOf(org.id!) > -1}
                                onChange={(e) => {
                                    setOrgId(org.id!, e.target.checked);
                                }}
                            />
                            {org.name}
                        </label>
                    ))}
                    <br />
                    <br />
                    <br />
                    <button
                        type="submit"
                        aria-busy={isSubmitting ? 'true' : 'false'}
                    >
                        Submit
                    </button>
                </form>
                <button className="warning" onClick={onClose}>
                    Cancel
                </button>
            </article>
        </dialog>
    );
}
