import {
    getFirestore,
    collection,
    query,
    where,
    getDocs,
    addDoc,
    collectionGroup,
    doc,
    setDoc,
    deleteDoc,
    getDoc,
    documentId,
} from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';

import User from '../models/User';
import Organisation from '../models/Organisation';
import Device from '../models/Device';
import Shoot from '../models/Shoot';

export * from './firebase';
export * from './functions';

const auth = getAuth();
const db = getFirestore();

const COLLECTIONS = {
    Shoots: 'shoots_test',
    Organisations: 'organisations',
};

export async function login(email: string, password: string) {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
        console.error('login error', error);
        throw new Error(error.message);
    }
}

export async function getUsers(): Promise<User[]> {
    let users: User[] = [];
    const querySnapshot = await getDocs(collection(db, 'users'));
    querySnapshot.forEach((doc) => {
        const user = doc.data();
        user.id = doc.id;
        users.push(user as User);
    });

    return users;
}

export async function getUser(userId: string): Promise<User> {
    const d = await getDoc(doc(db, 'users', userId));

    const user = d.data() as User;
    user.id = d.id;

    return user;
}

export async function getOrganisations(
    orgIds?: string[]
): Promise<Organisation[]> {
    let orgs: Organisation[] = [];

    let q = query(collection(db, 'organisations'));
    if (orgIds) {
        q = query(
            collection(db, 'organisations'),
            where(documentId(), 'in', orgIds)
        );
    }

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        const org = doc.data();
        org.id = doc.id;
        orgs.push(org as Organisation);
    });

    return orgs;
}

export async function createOrganisation(org: Organisation) {
    // TODO try,catch
    await addDoc(collection(db, 'organisations'), org);
}

export async function updateOrganisation(orgId: string, org: Organisation) {
    // TODO try,catch
    await setDoc(doc(db, 'organisations', orgId), org);
}

export async function getDevices(orgIds?: string[]): Promise<Device[]> {
    let devices: Device[] = [];
    let data;

    if (!orgIds) {
        try {
            data = await getDocs(collectionGroup(db, 'devices'));
        } catch (e) {
            console.error(e);
            throw e;
        }

        data.forEach((doc) => {
            const device = doc.data();
            device.id = doc.id;
            device.organisationId = doc.ref.parent.parent?.id;
            devices.push(device as Device);
        });
    } else {
        await Promise.all(
            orgIds.map(async (orgId) => {
                try {
                    data = await getDocs(
                        collection(db, 'organisations', orgId, 'devices')
                    );
                } catch (e) {
                    console.error(e);
                    throw e;
                }

                data.forEach((doc) => {
                    const device = doc.data();
                    device.id = doc.id;
                    device.organisationId = orgId;
                    devices.push(device as Device);
                });
            })
        );
    }

    return devices;
}

export async function createDevice(name: string, orgId: string) {
    await addDoc(collection(db, 'organisations', orgId, 'devices'), {
        name,
        streamId: uuidv4(),
    });
}

export async function getShoots(orgIds?: string[]): Promise<Shoot[]> {
    let data: Shoot[] = [];
    let res;

    let q = query(collection(db, COLLECTIONS.Shoots));

    if (orgIds) {
        q = query(
            collection(db, COLLECTIONS.Shoots),
            where('organisationId', 'in', orgIds)
        );
    }

    try {
        res = await getDocs(q);
    } catch (e) {
        console.error(e);
        throw e;
    }

    res.forEach((doc) => {
        const item = doc.data();
        data.push(item as Shoot);
    });

    return data;
}

export async function createShoot(shoot: Shoot): Promise<string> {
    shoot.id = uuidv4();
    await setDoc(doc(db, COLLECTIONS.Shoots, shoot.id), shoot);
    return shoot.id;
}

export async function updateShoot(shoot: Shoot) {
    await setDoc(doc(db, COLLECTIONS.Shoots, shoot.id), shoot);
}

export async function deleteShoot(shootId: string) {
    await deleteDoc(doc(db, COLLECTIONS.Shoots, shootId));
}
