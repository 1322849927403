import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Device from '../../models/Device';
import Organisation from '../../models/Organisation';
import Shoot from '../../models/Shoot';
import User from '../../models/User';
import { RootState } from '../store';

import * as api from './../../api';

export interface AppState {
    users: User[];
    isFetchingUsers: boolean;
    organisations: Organisation[];
    isFetchingOrganisations: boolean;
    devices: Device[];
    isFetchingDevices: boolean;
    shoots: Shoot[];
    isFetchingShoots: boolean;
}

const initialState: AppState = {
    users: [],
    isFetchingUsers: false,
    organisations: [],
    isFetchingOrganisations: false,
    devices: [],
    isFetchingDevices: false,
    shoots: [],
    isFetchingShoots: false,
};

export const getUsers = createAsyncThunk(
    'app/getUsers',
    async (): Promise<User[]> => {
        return await api.getUsers();
    }
);

export const getOrganisations = createAsyncThunk(
    'app/getOrganisations',
    async (_, thunkApi): Promise<Organisation[]> => {
        const {
            auth: { user },
        } = thunkApi.getState() as RootState;

        if (!user) {
            return [];
        }

        return await api.getOrganisations(user.organisationIds);
    }
);

export const getDevices = createAsyncThunk(
    'app/getDevices',
    async (_, thunkApi): Promise<Device[]> => {
        const {
            auth: { user },
        } = thunkApi.getState() as RootState;

        if (!user) {
            return [];
        }

        return await api.getDevices(user.organisationIds);
    }
);

export const getShoots = createAsyncThunk(
    'app/getShoots',
    async (_, thunkApi): Promise<Shoot[]> => {
        const {
            auth: { user },
        } = thunkApi.getState() as RootState;

        if (!user) {
            return [];
        }

        return await api.getShoots(user.organisationIds);
    }
);

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setOption: (state, action: PayloadAction<boolean>) => {
            // state.myOption = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state, action) => {
                state.isFetchingUsers = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.users = action.payload;
                state.isFetchingUsers = false;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.isFetchingUsers = false;
            });

        builder
            .addCase(getOrganisations.pending, (state, action) => {
                state.isFetchingOrganisations = true;
            })
            .addCase(getOrganisations.fulfilled, (state, action) => {
                state.organisations = action.payload;
                state.isFetchingOrganisations = false;
            })
            .addCase(getOrganisations.rejected, (state, action) => {
                state.isFetchingOrganisations = false;
            });

        builder
            .addCase(getDevices.pending, (state, action) => {
                state.isFetchingDevices = true;
            })
            .addCase(getDevices.fulfilled, (state, action) => {
                state.devices = action.payload;
                state.isFetchingDevices = false;
            })
            .addCase(getDevices.rejected, (state, action) => {
                state.isFetchingDevices = false;
            });

        builder
            .addCase(getShoots.pending, (state, action) => {
                state.isFetchingShoots = true;
            })
            .addCase(getShoots.fulfilled, (state, action) => {
                state.shoots = action.payload;
                state.isFetchingShoots = false;
            })
            .addCase(getShoots.rejected, (state, action) => {
                state.isFetchingShoots = false;
            });
    },
});

// export const {} = appSlice.actions;
export default appSlice.reducer;
