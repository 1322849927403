import { getAuth } from 'firebase/auth';
import User from '../models/User';

const envApiHost =
    process.env.REACT_APP_API_HOST || 'https://api.shoothub.io/api';

const API_HOST =
    window.location.hostname === 'localhost'
        ? 'http://localhost:3002/api'
        : envApiHost;

const auth = getAuth();

async function call(method: string, path: string, data?: any) {
    const authtoken = await auth.currentUser?.getIdToken();
    if (!authtoken) {
        console.error('authtoken not present');
        return;
    }

    const url = API_HOST + path;
    const res = await fetch(url, {
        method,
        body: data ? JSON.stringify(data) : undefined,
        headers: {
            'X-Auth-Token': authtoken,
            'Content-Type': 'application/json',
        },
    });

    if (res.status !== 200) {
        throw await res.text();
    }

    const resData = await res.json();
    return resData;
}

export async function setShootPassword(shootId: string, password: string) {
    const data = await call('POST', `/shoots/${shootId}/setPassword`, {
        password,
    });
    return data;
}

export async function getUploadUrl(extension: string): Promise<string> {
    const data = await call('POST', '/s3/generate-upload-url', { extension });

    return data.url;
}

export async function createUser(user: User & { password: string }) {
    const data = await call('POST', `/users/create`, user);
    return data;
}

export async function syncDailyRooms(): Promise<void> {
    await call('GET', '/sync-daily-rooms');
}
