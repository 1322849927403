import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { login } from '../state/features/auth';

import { useAppDispatch, useAppSelector } from '../state/store';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();

    const { isLoggingIn, user } = useAppSelector((s) => ({
        isLoggingIn: s.auth.isLoggingIn,
        user: s.auth.user,
    }));

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (user) {
            history.push('/');
        } else {
            console.log('not logged in!');
        }
    }, [user, history]);

    function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (isLoggingIn) return;
        dispatch(login({ email, password }));
    }

    return (
        <div className="login_page">
            <main className="container">
                <article className="grid">
                    <div>
                        <img
                            src="/assets/img/shoot-hub-black.png"
                            alt="Shoot Hub Logo"
                        />
                        <br />
                        <br />
                        <br />

                        <form onSubmit={onSubmit}>
                            <input
                                type="text"
                                placeholder="E-Mail"
                                aria-label="E-Mail"
                                autoComplete="email"
                                required
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                aria-label="Password"
                                autoComplete="current-password"
                                required
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                            <fieldset>
                                <label htmlFor="remember">
                                    <input
                                        type="checkbox"
                                        role="switch"
                                        id="remember"
                                        name="remember"
                                    />
                                    Remember me
                                </label>
                            </fieldset>
                            <button
                                type="submit"
                                className="contrast"
                                aria-busy={isLoggingIn ? 'true' : 'false'}
                            >
                                Login
                            </button>
                        </form>
                    </div>
                    <div></div>
                </article>
            </main>
        </div>
    );
}
